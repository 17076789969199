import React from 'react'

// const emPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export default class Subscribe extends React.Component {
	constructor(props) {
		super(props)
		this.inputRef = React.createRef()
		this.state = {
			submitted: false,
			inProgress: false,
		}
	}

	async submitEmail(email) {
		this.setState({ inProgress: true })
		const url = 'https://jarvis.aviaryan.com/subscribe'
		try {
			const res = await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email }),
			})
			const json = await res.json()
			if (json.status) {
				this.setState({ submitted: true })
			}
		} catch {
			this.setState({ inProgress: false })
		}
	}

	onSubmit(e) {
		const email = this.inputRef.current.value
		// emPattern.test(email)
		if (email) {
			this.submitEmail(email)
		}
		// stop the form from submitting
		e.preventDefault()
		return false
	}

	render() {
		return (
			<div className="subscribe">
				{this.state.submitted ? (
					<p>
						Please check your inbox for a confirmation email. Be sure to check <b>SPAM</b> folder.
					</p>
				) : (
					<>
						<h4>Subscribe to my email list</h4>
						<form method="" className="mail-form">
							<input type="email" ref={this.inputRef} placeholder="joe@doe.com" />
							<button onClick={this.onSubmit.bind(this)} disabled={this.state.inProgress}>
								{this.state.inProgress ? 'PLEASE WAIT...' : 'I AM IN!'}
							</button>
						</form>
					</>
				)}
			</div>
		)
	}
}
