import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Subscribe from '../components/subscribe'

export default function EmailTemplate({ data }) {
	const { markdownRemark } = data
	const { frontmatter, html, fields, excerpt } = markdownRemark
	return (
		<Layout title={frontmatter.title}>
			<div
				style={{
					backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%),
						url(${frontmatter.image})`,
				}}
				className="overlay"
			/>

			<div className="article email-article">
				<Helmet>
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:site" content="@aviaryan123" />
					<meta name="twitter:title" content={frontmatter.title} />
					<meta name="twitter:description" content={excerpt} />
					<meta name="twitter:creator" content="@aviaryan123" />
					<meta name="twitter:image:src" content={frontmatter.image} />

					<meta property="og:title" content={frontmatter.title} />
					<meta property="og:type" content="website" />
					<meta property="og:url" content={'https://aviaryan.com/' + fields.slug} />
					<meta property="og:image" content={frontmatter.image} />
					<meta property="og:description" content={excerpt} />
					<meta property="og:site_name" content="Avi Aryan" />

					<meta property="article:published_time" content={frontmatter.date} />
					<meta property="article:author" content="https://www.facebook.com/aviaryan123" />
					<meta property="article:publisher" content="https://www.facebook.com/aviaryan123" />
					<meta property="article:section" content="blog" />
					<meta property="article:tag" content={frontmatter.tags} />

					<meta name="keywords" content={frontmatter.keywords} />
					<meta name="description" content={excerpt} />
				</Helmet>
				<h1>{frontmatter.title}</h1>
				<h2 className="post-date">{frontmatter.date}</h2>
				<div className="post-content" dangerouslySetInnerHTML={{ __html: html }} />
				<hr />
				<Subscribe />
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			excerpt(pruneLength: 280)
			frontmatter {
				title
				tags
				keywords
				date(formatString: "MMMM DD, YYYY")
				image
			}
			fields {
				slug
			}
		}
	}
`
